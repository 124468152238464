export const config = {
  baseUrl: 'https://demo.circul8.eu/',
  ApiUrl: 'https://demo.circul8.eu/api/',
  storageUrl: 'https://demo.circul8.eu/storage/',
  chunkUploadUrl: 'https://demo-chunk-upload.circul8.eu/api',
  websocketsAppKey: '097a0488-66ee-4b32-92cb-645daf482d2f',
  websocketsServer: 'demo-sockets.circul8.eu',
  appsToRedirectVue3: [
    // '29h9lguSsQVUk5EEqhRo6RljQnfrwO17', // ABREE
    // 'bEPiUABVEk8Rl1uZkM8P2TFy1QDmKj2F', // ERP Finland
    // 'Kfsgn48pxcHAMUFTS1LCCkxmT3zXKdvu', // ERP Brasil packaging
    // 'ExuADrgKgwVdzUBfddPVH5tJfMOGA6ei' // ERP Ireland
  ],
  vue3URL: 'https://new.circul8.world/'
}
